export const channelTypeList = [{
   value: 'all',
   label: '全部',
 }, {
   value: 'mendian',
   label: '网络渠道',
   children: [{
     value: 43,
     label: '微信'
   }, {
     value: 44,
     label: '抖音合作'
   }, {
     value: 50,
     label: '高德'
   }, {
     value: 51,
     label: '百度'
   }, {
     value: 52,
     label: '小红书'
   }, {
     value: 54,
     label: '抖音'
   }, {
     value: 55,
     label: '快手'
   }, {
     value: 118,
     label: '美团'
   }, {
     value: 584,
     label: '本地推'
   }, {
     value: 609,
     label: '抖团'
   }]
 }, {
   value: 'mentou',
   label: '门头外联',
   children: [{
     value: 46,
     label: '门头'
   }, {
     value: 47,
     label: '外联'
   }, {
     value: 48,
     label: '地推'
   }, {
     value: 654,
     label: '站点'
   }]
 },{
   value: 49,
   label: '转介绍',
 },
 {
   value: 'yiliao',
   label: '医疗',
   children: [{
     value: 597,
     label: '黄V'
   }, {
     value: 663,
     label: '爱甲'
   }, {
     value: 56,
     label: '医疗美团'
   }]
 }, 
 {
   value: 'xinxi',
   label: '信息流',
   children: [{
     value: 51,
     label: '百度'
   }, {
     value: 54,
     label: '抖音'
   }, {
     value: 52,
     label: '小红书'
   }, {
     value: 43,
     label: '微信'
   }, {
     value: 44,
     label: '抖音合作'
   }]
 },
 {
   value: 'tuangou',
   label: '团购',
   children: [{
     value: 118,
     label: '美团'
   }, {
     value: 50,
     label: '高德'
   }, {
     value: 55,
     label: '快手'
   }]
 },
 {
   value: 'bendi',
   label: '本地推',
   children: [{
     value: 609,
     label: '抖团'
   }, {
     value: 584,
     label: '本地推'
   }]
 }
];
export const taskChannelList = [{
  value: 1,
  label: '网络渠道',
},{
  value: 2,
  label: '门头外联',
},{
  value: 3,
  label: '转介绍',
},{
  value: 4,
  label: '医疗',
}]
